import React, { Fragment, PureComponent } from 'react'
import {
	getCurrentLangKey,
	getLangs,
	getUrlForLang,
	getSlugAndLang,
} from 'ptz-i18n'
import { addLocaleData, IntlProvider } from 'react-intl'
import 'intl'

import en from 'react-intl/locale-data/en'
import fr from 'react-intl/locale-data/fr'
import de from 'react-intl/locale-data/de'

addLocaleData([...en, ...fr, ...de])

import messagesEn from '../../data/translations/en.json'
import messagesFr from '../../data/translations/fr.json'
import messagesDe from '../../data/translations/de.json'

const messages = {
	en: messagesEn,
	fr: messagesFr,
	de: messagesDe,
}

import CookieConsent from '../CookieConsent/CookieConsent'

class PositionLayout extends PureComponent {

	render() {
		const { children, data, location, i18nMessages, pageContext } = this.props

		const url = location.pathname
		const { langs, defaultLangKey } = data.site.siteMetadata.languages
		const langKey = getCurrentLangKey(langs, defaultLangKey, url)
		const homeLink = `/${langKey}`
		const langsMenu = getLangs(langs, langKey, getUrlForLang(homeLink, url))
		const langSlugs = pageContext.slugs

		return (
			<IntlProvider locale={langKey} messages={messages[langKey]}>
				<div
					id="top"
					className="app app--no-pt">
					<main className="main">{children}</main>
					{/* <CookieConsent
						location="bottom"
						buttonText="Continue"
						cookieName="allowCookie"
						style={{ background: '#0c5495' }}
					/> */}
				</div>
				{/* <div id="modal"></div> */}
			</IntlProvider>
		)
	}
}

export default PositionLayout
