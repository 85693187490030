import React, { PureComponent } from 'react';

import linkedUrl, {
	ReactComponent as LinkedIcon,
} from '../../static/images/icons/vert_in.svg'
import twitterUrl, {
	ReactComponent as TwitterIcon,
} from '../../static/images/icons/vert_tw.svg'
import enveloepUrl, {
	ReactComponent as EnvelopeIcon,
} from '../../static/images/icons/envelope.svg'
import sharekeyUrl, {
	ReactComponent as SharekeyIcon,
} from '../../static/images/icons/vert_sk.svg'


import './styles.scss'

class ShareBox extends PureComponent {
	constructor(props) {
		super(props);
		this.state = {
			url: "",
		}
	}

	componentDidMount() {
		if (typeof window !== 'undefined') {
			this.setState({
				url: window.location.href
			})
		}
		this.setState({

		})
	}
	

	render() {
		const { text, title, description, className } = this.props
		const url = this.state.url
		return (
			<div className={`b-share-box ${className}`}>
				<span className="b-share-box__title">{text}</span>
				<ul className="b-share-box__list">
					<li className="b-share-box__list-item">
						<a href={`https://www.linkedin.com/shareArticle?mini=true&amp;url=${url}&title=${description}`}
							className="b-share-box__link"
							target="_blank"
							title="Share with LinkedIn"
							rel="noopener">
							<LinkedIcon className="b-share-box__icon b-share-box__icon--linked" />
					   </a>
					</li>
					<li className="b-share-box__list-item">
						<a href={`https://twitter.com/share?url=${url}`}
							className="b-share-box__link"
							target="_blank"
							title="Share with Twitter"
							rel="noopener">
							<TwitterIcon className="b-share-box__icon b-share-box__icon--twitter" />
					   </a>
					</li>
					<li className="b-share-box__list-item">
						<a href={`mailto:?subject=${description}&body=${url}`}
							className="b-share-box__link"
							title="Send by email"
							rel="noopener">
							<EnvelopeIcon className="b-share-box__icon b-share-box__icon--envelope" />
					   </a>
					</li>
					<li className="b-share-box__list-item">
					   <a href="" className="b-share-box__link">
							<SharekeyIcon className="b-share-box__icon b-share-box__icon--sharekey" />
					   </a>
					</li>
				</ul>
			</div>
		)
	}
}

export default ShareBox;
