import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Cookies from 'js-cookie'
import './index.scss'

export const OPTIONS = {
  TOP: 'top',
  BOTTOM: 'bottom',
}

class CookieConsent extends Component {
  constructor(props) {
    super(props)
    this.accept.bind(this)

    this.state = {
      isHidden: true,
      render: false,
      style: {
        position: 'fixed',
        width: '100%',
        left: '0',
        zIndex: '999',
        lineHeight: '30px',
        textAlign: 'left',
      },
      buttonStyle: {
        fontFamily: "'HeroNew', 'Helvetica Neue', 'Arial', sans-serif",
        border: '0',
        boxShadow: 'none',
        borderRadius: '3px',
        padding: '14px 35px',
        color: '#fff',
        lineHeight: '1',
        fontSize: '12px',
        fontWeight: '600',
        textTransform: 'uppercase',
        backgroundColor: '#3fc0c4',
        cursor: 'pointer',
      },
    }
  }

  componentDidMount() {
    const { cookieName } = this.props
    if (Cookies.get(cookieName) == undefined) {
      this.setState({
        render: true,
        isHidden: false,
      })
    }
  }

  /**
   * Set a persistent cookie
   */
  accept() {
    const { cookieName } = this.props

    Cookies.set(cookieName, true)
    this.setState({ isHidden: true })
  }

  render() {
    // If the bar shouldn't be visible don't render anything.
    if (!this.state.render) {
      return null
    }

    const {
      location,
      style,
      buttonStyle,
      disableStyles,
      onAccept,
      buttonText,
    } = this.props

    let myStyle = {},
      myButtonStyle = {}

    if (disableStyles) {
      // if styles are disabled use the provided styles (or non)
      myStyle = Object.assign({}, style)
      myButtonStyle = Object.assign({}, buttonStyle)
    } else {
      // if styles aren't disabled merge them with the styles that are provided (or use default styles)
      myStyle = Object.assign({}, { ...this.state.style, ...style })
      myButtonStyle = Object.assign(
        {},
        { ...this.state.buttonStyle, ...buttonStyle }
      )
    }

    // syntactic sugar to enable user to easily select top / bottom
    switch (location) {
      case OPTIONS.TOP:
        myStyle.top = '0'
        break

      case OPTIONS.BOTTOM:
        myStyle.bottom = '0'
        break
    }

    return (
      <div
        className={
          this.state.isHidden ? 'b_cookie' : 'b_cookie b_cookie--visible'
        }
        style={myStyle}
      >
        <div className="b_cookie__container">
          <div className="b_cookie__headers">
            <div className="b_cookie__title">Cookie notification</div>
            <div className="b_cookie__subtitle">We are compliant</div>
          </div>
          <div className="b_cookie__info">
            <p className="b_cookie__info-text">
              Like most websites we use cookies to deliver the best possible web
              experience. By counting and using the site, you consent to the use
              of cookies. If you want to disable them, please take a look at our{' '}
              <a href="#" className="b_cookie__info-link">
                cookie policy
              </a>{' '}
              page.
            </p>
            <span className="b_cookie__notice">
              Please note that the site may not function corrctly if you disable
              all cookies.
            </span>
          </div>
          {this.props.children}
          <button
            style={myButtonStyle}
            onClick={() => {
              this.accept()
              onAccept()
            }}
          >
            {buttonText}
          </button>
        </div>
      </div>
    )
  }
}

CookieConsent.propTypes = {
  location: PropTypes.oneOf(['top', 'bottom']),
  style: PropTypes.object,
  buttonStyle: PropTypes.object,
  children: PropTypes.any, // eslint-disable-line react/forbid-prop-types
  disableStyles: PropTypes.bool,
  onAccept: PropTypes.func,
  buttonText: PropTypes.string,
  cookieName: PropTypes.string,
}
CookieConsent.defaultProps = {
  disableStyles: false,
  location: OPTIONS.BOTTOM,
  onAccept: () => {},
  cookieName: 'CookieConsent',
  buttonText: 'I understand',
}

export default CookieConsent
export { Cookies }
