import React, { PureComponent, Fragment } from 'react';
import { Link } from 'gatsby'

import "./styles.scss"

import Modal from '../Modal/Modal'
import ModalApply from '../ModalApply/ModalApply';

class ApplyBtn extends PureComponent {
	constructor(props) {
		super(props)
		this.state = {
			showModal: false,
			modalShouldBeRender: false,
		}
		this.handleShow = this.handleShow.bind(this);
		this.handleHide = this.handleHide.bind(this);
	}

	handleShow(e) {
		this.setState({ showModal: true });
		document.querySelector('body').classList.add('body--fixed')
	}

	handleHide() {
		this.setState({ showModal: false });
		document.querySelector('body').classList.remove('body--fixed')
	}

	render() {
		const { onClick, text } = this.props
		const modal = this.state.showModal ? (
			<Modal color="white">
				<button className="modal__hide" onClick={this.handleHide}></button>
				<ModalApply modal={this.props.modal} position={this.props.position} location={this.props.location} close={() => this.handleHide()}/>
			</Modal>
		) : null;
		return ( 
			<Fragment>
				<button className="b-apply-btn" onClick={this.handleShow}>
					{text}
				</button>
				{modal}
			</Fragment>
		)
	}
}
 
export default ApplyBtn;