import React, { PureComponent, Fragment } from 'react'
import { Link, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'
import { format } from 'date-fns'
import Img from 'gatsby-image'

import PositionLayout from '../components/Layout/PositionLayout'
import SEO from '../components/SEO/SEO'
import ApplyBtn from '../components/ApplyBtn/ApplyBtn';
import SendBtn from '../components/SendBtn/SendBtn';
import ShareBox from '../components/ShareBox/ShareBox';

import './positionPage.scss'

import logoLabsUrl, { ReactComponent as LogoLabs } from '../static/images/logo_careers.svg'
import logoUrl, { ReactComponent as Logo } from '../static/images/logo_careers.svg'
import arrowUrl, {
	ReactComponent as Arrow,
} from '../static/images/icons/feat_arr.svg'
import pointerUrl, {
	ReactComponent as PointerIcon,
} from '../static/images/icons/adress.svg'

class PositionPage extends PureComponent {
	componentDidMount() {
		const links = this.description.querySelectorAll('a')
		const emailPattern = /\S+@\S+\.\S+/;
		Array.from(links).map(link => {
			if (link.innerText.match(emailPattern)) {
				link.href = `mailto:${link.innerText}`
			}
		})
	}
	render() {
		const data = this.props && this.props.data && this.props.data.contentfulCareersPositions
		const {
			backText,
			prevText,
			nextText,
			locationText,
			shareText,
			applyText,
			sendText,
			descriptionLong,
			textBeforePositionAmount,
			textAfterPositionAmount,
			positionAmountLink
			} = this.props.data.static
		const positions = this.props.data.positions.edges
		const applyModal = this.props.data.aplymodal.edges[0].node
		const sendModal = this.props.data.sendmodal.edges[0].node
		const { next, previous } = this.props.pageContext
		return (
			<PositionLayout {...this.props}>
				<Fragment>
					<SEO
						title={data.metaTitle}

						description={data.metaDescription}
						ogDescription={data.metaOgDescription}
						twitterDescription={data.metaTwitterDescription}

						cover={data.metaImage && data.metaImage.file.url}
						ogCover={data.metaOgImage && data.metaOgImage.file.url}
						twitterCover={data.metaTwitterImage && data.metaTwitterImage.file.url}

						slug={this.props.data.contentfulCareersPositions.slug}
						lang={this.props.pageContext.langKey}
						slugs={this.props.pageContext.slugs}

						fbappid={this.props.data.globalConfig.fbappid}
						twitterUser={this.props.data.globalConfig.twitterUser}
						location={this.props.location}
					/>
					<div className="b-positions-page__gray-bg">
						<section className="g-container g-container--no-pad g-container--position">
							<div className="b-positions-page">
								<div className="b-positions-page__col b-positions-page__col--bg b-positions-page__col--sticky">
									<div className="b-positions-page__header">
										<Link to="/careers/open-positions" className="b-positions-page__back-link b-positions-page__back-link--middle" >
											<Arrow className="b-positions-page__arrow b-positions-page__arrow--prev" />
										</Link>
										<Link to="/careers" className="b-positions-page__logo-link">
											{data.logo[0] === "labs"
												? <LogoLabs className="b-positions-page__logo" />
												: <Logo className="b-positions-page__logo" />}
										</Link>
										<Link to="/careers/open-positions" className="b-positions-page__back-link">
											<Arrow className="b-positions-page__arrow b-positions-page__arrow--prev" />
											{backText}
										</Link>
									</div>
									<div className="b-positions-page__short-description">
										<div className="b-positions-page-short-description__department">{data.department.title}</div>
										<h1 className="b-positions-page-short-description__title">{data.title}</h1>
										<div className="b-positions-page-short-description__description t-paragraph t-paragraph--dark" dangerouslySetInnerHTML={{
											__html: data.descriptionLong.childMarkdownRemark.html,
										}}/>
										<ApplyBtn text={applyText} modal={applyModal} position={data.title} location={data.region[0].replace(/<\/?[^>]+(>|$)/g, "")}/>
									</div>
									<div className="b-positions-page__navigation">
										{previous && <Link to={previous ? `/${previous}` : "#"}
											className="b-positions-page__navigation-link b-positions-page__navigation-link--prev">
											<Arrow className="b-positions-page__arrow b-positions-page__arrow--gray b-positions-page__arrow--prev" />
											{prevText}
										</Link>}
										{next && <Link to={next ? `/${next}` : "#"}
											className="b-positions-page__navigation-link b-positions-page__navigation-link--next">
											{nextText}
											<Arrow className="b-positions-page__arrow b-positions-page__arrow--gray b-positions-page__arrow--next" />
										</Link>}
									</div>
								</div>
								<div className="b-positions-page__col">
									<div className="b-positions-page__header b-positions-page__header--tablet-half-mb">
										<time className="b-positions-page__date" dateTime={data.customDate}>{format(data.customDate, 'Do MMMM YYYY')}</time>
										<span className="b-positions-page__location">
											<PointerIcon className="b-positions-page__location-pointer" /><span dangerouslySetInnerHTML={{
												__html: data.region,
											}}/>
										</span>
									</div>
									<div ref={(node) => {this.description = node}} className="b-positions-page__full-description" dangerouslySetInnerHTML={{
											__html: data.fullDescription.childMarkdownRemark.html,
										}}/>
									<div className="b-positions-page__buttons">
										<ApplyBtn text={applyText} modal={applyModal} position={data.title} location={data.region[0].replace(/<\/?[^>]+(>|$)/g, "")}/>
									</div>
									<ShareBox
										text={shareText}
										title={data.metaTitle}
										description={data.metaDescription}
										className="b-positions-page__share" />
									<div className="b-positions-page__about" dangerouslySetInnerHTML={{
										__html: descriptionLong.childMarkdownRemark.html,
									}} />
									<Link to={positionAmountLink} className="b-positions-page__advert">
										{textBeforePositionAmount} {positions.length - 1} {textAfterPositionAmount}
									</Link>
								</div>
								<div className="b-positions-page__navigation b-positions-page__navigation--tablet">
									{previous && <Link to={previous ? `/${previous}` : "#"}
										className="b-positions-page__navigation-link b-positions-page__navigation-link--prev">
										<Arrow className="b-positions-page__arrow b-positions-page__arrow--gray b-positions-page__arrow--prev" />
										{prevText}
									</Link>}
									{next && <Link to={next ? `/${next}` : "#"}
										className="b-positions-page__navigation-link b-positions-page__navigation-link--next">
										{nextText}
										<Arrow className="b-positions-page__arrow b-positions-page__arrow--gray b-positions-page__arrow--next" />
									</Link>}
								</div>
							</div>
						</section>
					</div>
				</Fragment>
			</PositionLayout>
		)
	}
}

export const pageQuery = graphql`
	query positionsQuery($id: String!, $locale: String!) {
		site {
			siteMetadata {
			languages {
				defaultLangKey
				langs
			}
		}
	}
	static: contentfulCareersPositionsStatic {
		backText
		prevText
		nextText
		locationText
		shareText
		applyText
		sendText
		descriptionLong {
			childMarkdownRemark {
				html
			}
		}
		textBeforePositionAmount
		textAfterPositionAmount
		positionAmountLink
	}
	globalConfig: contentfulGlobalConfig {
		fbappid
		twitterUser
	}
	aplymodal: allContentfulCareersPositionsApplyModal(filter: { node_locale: { eq: $locale } }) {
		edges {
			node {
				subTitle
				firstNamePlaceholder
				lastNamePlaceholder
				emailPlaceholder
				phonePlaceholder
				portfolioPlaceholder
				uploadCvText
				agreementCheckboxText
				linkToPolicyText
				linkToPolicy {
					slug
				}
				submitButtonText
				emptyFieldError
				wrongEmailError
				wrongPortfolioUrl
				tankTitle
				thankSubtitle
			}
		}
	}
	sendmodal: allContentfulCareersPositionSendUsModal(filter: { node_locale: { eq: $locale } }) {
		edges {
			node {
				title
				subTitle
				firstNamePlaceholder
				lastNamePlaceholder
				emailPlaceholder
				phonePlaceholder
				portfolioPlaceholder
				uploadCvText
				agreementCheckboxText
				linkToPolicyText
				locationPlaceholder
				jobTitlePlaceholder
				whyApplyPlaceholder
				linkToPolicy {
					slug
				}
				submitButtonText
				emptyFieldError
				wrongEmailError
				wrongPortfolioUrl
				tankTitle
				thankSubtitle
			}
		}
	}
	positions: allContentfulCareersPositions(filter: { node_locale: { eq: $locale } }) {
		edges {
			node {
				id
			}
		}
	}
	contentfulCareersPositions(id: { eq: $id }) {
		title
		metaTitle
		metaDescription
		metaOgDescription
		metaTwitterDescription
		metaImage {
			file {
				url
			}
		}
		metaOgImage {
			file {
				url
			}
		}
		metaTwitterImage {
			file {
				url
			}
		}
		descriptionLong {
			descriptionLong
			childMarkdownRemark {
				html
			}
		}
		fullDescription {
			childMarkdownRemark {
				html
			}
		}
		createdAt
		department {
			title
		}
		customDate
		region
		logo
		slug
	}
}
`

export default PositionPage
